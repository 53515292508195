import { checkTenement, developerSubmit } from "@lib";
export default {
  data() {
    return {
      applications: [],
      form: {
        account: "",
        tenementId: "",
        applications: [],
        reason: "",
      },
    };
  },
  methods: {
    async onAccountChange() {
      if (this.form.account !== "") {
        this.form.tenementId = "";
        let { status, result } = await checkTenement(`"${this.form.account}"`);
        if (status === "success") {
          this.form.tenementId = result?.tenementId;
          this.applications = result?.list || [];
        } else {
          this.form.account = "";
          this.applications = [];
          this.$message.error(result.error_msg);
        }
      }
    },
    async onSubmit() {
      if (this.form.tenementId !== "") {
        let { status, result } = await developerSubmit({
          ...this.form,
          applications: this.applications
            .filter((m) => this.form.applications.includes(m.name))
            .map((m) => m.id),
          account: undefined,
        });
        if (status === "success") {
          this.$message.success("申请成功");
          this.$router.replace({ name: "accountList" });
        } else {
          this.$message.error(result.error_msg);
        }
      } else {
        this.$message.error("租户账号不存在");
      }
    },
    onClose() {
      this.$router.back();
    },
  },
};
